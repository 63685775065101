.swipe-button-root {
  border-color: #008A94 !important;
  border-width: 0.25rem !important;
  background: #008A94 !important;
  border-radius: 2rem !important;
  height: 4rem !important;
}
.swipe-button-caret {
  background: #fff !important;
  color: #008A94 !important;
  margin-right: 0.5625rem !important;
  padding: 8px !important;
}
.swipe-button-caret svg {
  padding-top: 0.0625rem;
}
.swipe-button-caret svg .cls-1 {
  fill: #008A94 !important;
}
.swipe-button-overlay {
  background: #fff !important;
  color: #008A94 !important;
  border-top-right-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
  border-width: 2rem !important;
  min-width: 58px !important;
  max-width: 101% !important;
}
.swipe-button-root-checkout {
  border-color: #fff !important;
  border-width: 0.1875rem !important;
  background: #fff !important;
  border-radius: 2rem !important;
  height: 4rem !important;
  box-shadow:
  0 0 0 0.125rem #E7F0F0;
}
.swipe-button-caret-checkout {
  background: #06002C !important;
  color: #fff !important;
  margin-right: 0.5625rem !important;
  padding: 8px !important;
}
.swipe-button-caret-checkout svg {
  padding-top: 0.0625rem;
}
.swipe-button-caret-checkout svg .cls-1 {
  fill: #fff !important;
}
.swipe-button-overlay-checkout {
  background: #06002C !important;
  color: #fff !important;
  border-top-right-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
  border-width: 2rem !important;
  min-width: 58px !important;
  max-width: 101% !important;
}
.swipe-button-root-unavailable {
  border-color: #E7F0F0 !important;
  border-width: 0.25rem !important;
  background: #E7F0F0 !important;
  border-radius: 2rem !important;
  height: 4rem !important;
}
.swipe-button-caret-unavailable {
  background: #E7F0F0 !important;
  color: #999 !important;
  margin-right: 0.5625rem !important;
  padding: 8px !important;
}
.swipe-button-caret-unavailable svg {
  padding-top: 0.0625rem;
}
.swipe-button-caret-unavailable svg .cls-1 {
  fill: #999 !important;
}
.swipe-button-overlay-unavailable {
  background: #E7F0F0 !important;
  color: #999 !important;
  border-top-right-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
  border-width: 2rem !important;
  width: 58px !important;
}
