.geosuggest__item::before {
  content: '';
  background-image: url('data:image/svg+xml;utf-8,<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0949 10.7611L8.1896 14.6663L4.28433 10.7611C3.51195 9.98869 2.98596 9.00461 2.77287 7.93328C2.55977 6.86196 2.66915 5.7515 3.08716 4.74234C3.50517 3.73317 4.21305 2.87063 5.12128 2.26377C6.0295 1.65692 7.09728 1.33301 8.1896 1.33301C9.28191 1.33301 10.3497 1.65692 11.2579 2.26377C12.1661 2.87063 12.874 3.73317 13.292 4.74234C13.7101 5.7515 13.8194 6.86196 13.6063 7.93328C13.3932 9.00461 12.8672 9.98869 12.0949 10.7611ZM8.1896 9.31041C8.8406 9.31041 9.46493 9.0518 9.92526 8.59147C10.3856 8.13114 10.6442 7.50681 10.6442 6.85581C10.6442 6.20481 10.3856 5.58047 9.92526 5.12014C9.46493 4.65982 8.8406 4.40121 8.1896 4.40121C7.5386 4.40121 6.91426 4.65982 6.45393 5.12014C5.99361 5.58047 5.735 6.20481 5.735 6.85581C5.735 7.50681 5.99361 8.13114 6.45393 8.59147C6.91426 9.0518 7.5386 9.31041 8.1896 9.31041ZM8.1896 8.08311C7.8641 8.08311 7.55193 7.9538 7.32177 7.72364C7.0916 7.49348 6.9623 7.18131 6.9623 6.85581C6.9623 6.53031 7.0916 6.21814 7.32177 5.98798C7.55193 5.75781 7.8641 5.62851 8.1896 5.62851C8.5151 5.62851 8.82727 5.75781 9.05743 5.98798C9.28759 6.21814 9.4169 6.53031 9.4169 6.85581C9.4169 7.18131 9.28759 7.49348 9.05743 7.72364C8.82727 7.9538 8.5151 8.08311 8.1896 8.08311Z" fill="%23008A94"></path></svg>');
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  background-position: center;
  background-size: contain;
  margin-right: 0.4rem;
  min-width: 1.2rem;
  background-repeat: no-repeat;
}

input[type=text] {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}
